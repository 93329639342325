@import "./base";

:root {
	--color-primary: #{$color-primary};
	--color-primary-accent: #{$color-primary-accent};
	--color-primary-disabled: #{mix($color-primary, #fff)};
	--color-primary-accent-disabled: #{mix($color-primary-accent, #fff)};
}

* {
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: Nunito, sans-serif;
	font-size: 16px;
	color: #333;
}

fieldset {
	margin: 0;
	padding: 0;
	border: 0;
}

input, select, button {
	-webkit-appearance: none;
	border-radius: 1rem;
	padding: .3125rem .525rem;
	font-family: Nunito, sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
	border: 0;
	outline: 0;
}

input, select {
	color: #4b4b4b;
	border: .125rem solid #e6e6e6;

	&:disabled {
		background: #fafafa;
	}
}

button {
	position: relative;
	background: var(--color-primary);
	color: #fff;
	text-transform: uppercase;
	font-weight: bold;
	margin-bottom: .25rem;

	&::after {
		content: "";
		position: absolute;
		top: .25rem;
		bottom: -.25rem;
		left: 0;
		right: 0;
		z-index: -1;
		border-radius: 1rem;
		background: var(--color-primary-accent);
	}

	&:not(:disabled):active {
		margin-top: .25rem;
		margin-bottom: 0;
		&::after {
			border: 0;
			bottom: 0;
		}
	}

	&:not(:disabled) {
		cursor: pointer;
	}

	&:disabled {
		background: var(--color-primary-disabled);
		&::after {
			background: var(--color-primary-accent-disabled);
		}
	}

	&.loading {
		color: transparent;
		& > * {
			opacity: 0;
		}
		&::before {
			content: "";
			position: absolute;
			font-size: .75rem;
			width: 1em;
			height: 1em;
			top: calc(50% - .5em);
			left: calc(50% - .5em);
			border: .125rem solid #fff;
			border-bottom-color: transparent;
			border-radius: 1rem;
			animation: spin 2s linear infinite;

			@keyframes spin {
				from { transform: rotate(0deg); }
				to { transform: rotate(360deg); }
			}
		}
	}
}

.row {

	display: flex;
	align-items: center;
	justify-content: center;

	&.force-margin, &:not(:last-child) {
		margin-bottom: 1rem;
	}

	& > .label {
		width: 5rem;
		text-align: right;
		font-weight: bold;
		padding-right: 1rem;
		white-space: nowrap;
	}

	& > .value {
		flex-grow: 1;
		input, select {
			width: 100%;
		}
	}

	& > .group {
		display: flex;
		& > * + * {
			margin-left: .5rem;
		}
		button {
			min-width: 4rem;
		}
	}

	@media screen and (max-width: $mobile) {

		flex-direction: column;

		& > .label {
			width: 100%;
			text-align: left;
			padding-left: 1rem;
			padding-bottom: .5rem;
		}

		& > .value {
			width: 100%;
		}

	}

}
