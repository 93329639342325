@import "./base";

.results {

	display: flex;
	width: 100%;
	align-items: center;
	margin-top: .5rem;

	& > .cards {
		flex-grow: 1;
	}

	& > .result {
		font-size: 1.25rem;
		font-weight: bold;
		text-align: center;
		width: 14rem;

		.amount {
			color: #58a700;
		}

		.currency {
			color: #555;
			font-size: 1rem;
		}
	}

	.cards-component .card {
		font-size: 1.75rem;
	}

	@media screen and (max-width: $mobile) {

		flex-direction: column;

		.cards {
			width: 100%;
		}

		.result {
			padding-top: .5rem;
		}

		.cards-component .card {
			font-size: 6vw;
		}

	}

}
