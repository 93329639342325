.footer-component {

	text-align: center;
	font-size: .75rem;
	margin: 1.5rem 0;

	.links {
		margin: 1rem;

		a {
			color: #333;
			margin: 0 .25rem;
		}
	}

	.suits {
		display: inline-flex;
		align-items: center;
	}

	.suit {
		width: 2rem;
		height: 2rem;

		& + .suit {
			margin-left: 1rem;
		}
	}

	.hearts {
		background: url(../assets/suits/hearts.svg);
		background-size: 100%;
	}

	.diamonds {
		background: url(../assets/suits/diamonds.svg);
		background-size: 100%;
	}

	.clubs {
		background: url(../assets/suits/clubs.svg);
		background-size: 100%;
	}

	.spades {
		background: url(../assets/suits/spades.svg);
		background-size: 100%;
	}

}
