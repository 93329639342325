@import "./base";

.history-component {

	.history-component-game {
		margin-top: 1rem;
	}

	.history-component-footer {

		.spacer {
			flex-grow: 1;
		}

		button {
			font-size: 2rem;
			min-width: 4rem;
		}

		.nav + .nav {
			margin-left: .5rem;
		}

	}

	.history-component-empty {
		margin-top: 1.5rem;
		text-align: center;
		font-size: 1.25rem;
	}

	@media screen and (max-width: $mobile) {

		.history-component-footer {

			flex-direction: row;

		}

	}

}
